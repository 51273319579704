import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"

const service = new Service();

export const GetListAll = (categoryId=1,title="",isHot=undefined, isFeature=undefined,isDelete=undefined,status=undefined,
    pageIndex = 1, pageSize = config.Configs.DefaultPageSize, sortExpression = "modifiedDate desc") => {
    const params = new URLSearchParams();
    params.append('categoryId', categoryId);
    params.append('isHot', isHot);
    params.append('isFeature', isFeature);
    params.append('isDelete', isDelete);
    params.append('status', status);
    params.append('pageIndex',pageIndex);
    params.append('pageSize',pageSize);
    params.append('sorting',sortExpression);
    title && params.append('title',title);
    return service.get(ApiUrl.News_GetListAll,params).then(res => { return res }).catch(err => { throw err });
}

export const GetDetailNews = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service.get(ApiUrl.News_GetDetail.replace("{id}",id)).then(res => { return res }).catch(err => { throw err });
}

export const CreateNews = (body) => {
    return service.post(ApiUrl.News_Create, body).then(res => { return res }).catch(err => { throw err });
}

export const UpdateNews = (body) => {
    return service.post(ApiUrl.News_Update, body).then(res => { return res }).catch(err => { throw err });
}

export const DeleteNews = (id, xmin) => {
    //const params = new URLSearchParams();
    //params.append("id",id);
    return service.postParams(ApiUrl.News_Delete + "/" + id + "/" + xmin).then(res => { return res }).catch(err => { throw err });
}
// export const DeletePlanningType = (id) => {
//     return service.postParams(ApiUrl.DeletePlanningType + "/" + id ).then(res => { return res }).catch(err => { throw err });
// }
export const DeletePlanningType = (data) => {
    const params = new URLSearchParams();
    params.append("id", data);
    return service.postParams(ApiUrl.DeletePlanningType, params).then((res) => { return res }).catch(err => { throw err });
}
export const EmptyTrash = () => {
    return service.post(ApiUrl.News_EmptyTrash).then(res => { return res }).catch(err => { throw err });
}

export const RestoreNews = (id) => {
    return service.postParams(ApiUrl.News_Restore.replace("{id}", id)).then(res => { return res }).catch(err => { throw err });
}

export const GetListAllPlanningType = () => {
    return service.get(ApiUrl.GetListAllPlanningType).then(res => { return res }).catch(err => { throw err });
}

export const GetDetailPlanningType = (id) => {
    return service.get(ApiUrl.GetDetailPlanningType + id).then(res => { return res }).catch(err => { throw err });
}

export const UpdatePlanningType = (body) => {
    return service.post(ApiUrl.UpdatePlanningType, body).then(res => { return res }).catch(err => { throw err });
}



export const CreatePlanningType = (body) => {
    return service.post(ApiUrl.CreatePlanningType, body).then(res => { return res }).catch(err => { throw err });
}
